import request from '../request'

export const getScoreInfo = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/scoreintegralinfos/info`,
        data: params,
        method: 'GET',
    })
};

export const getPairingScore = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/scoreintegralinfos/paring/info`,
        data: params,
        method: 'GET',
    })
};

export const getScoreRule = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/scoreintegralinfos/rule`,
        params: params,
        method: 'GET',
    })
};